.mat-mdc-card {
  // mat-card border radius
  --mdc-elevated-card-container-shape: 12px;
}

.mat-mdc-button,
.mat-mdc-raised-button,
.mat-mdc-unelevated-button {
  --mdc-shape-small: 35px;
}

[flowTheme^="flow_"], [flowTheme^="campus_"] > .mat-mdc-dialog-panel {
    border: none !important;
}

[flowTheme^="flow_"], [flowTheme^="campus_"] {
  .border-theme-primary {
    border-color: var(--color-primary) !important;
  }

  .mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
    --mat-tab-header-active-label-text-color: var(--color-primary);
    --mdc-tab-indicator-active-indicator-color: --color-primary;
    --mat-tab-header-active-hover-label-text-color: var(--color-primary-with-opacity);
    --mat-tab-header-inactive-hover-label-text-color: var(--color-primary-with-opacity);
    --mdc-tab-indicator-active-indicator-color: --color-primary
    --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
    --mat-tab-header-pagination-icon-color: white;
    --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
    --mat-tab-header-active-label-text-color: var(--color-primary);
    --mat-tab-header-active-ripple-color: var(--color-primary);
    --mat-tab-header-inactive-ripple-color: var(--color-primary);
    --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
    --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
    --mat-tab-header-active-focus-label-text-color: var(--color-primary);
    --mat-tab-header-active-hover-label-text-color: var(--color-primary);
    --mat-tab-header-active-focus-indicator-color: var(--color-primary);
    --mat-tab-header-active-hover-indicator-color: var(--color-primary);
  }

  mat-label {
    color: var(--color-primary) !important;
  }

  .mat-mdc-form-field-required-marker, .mat-mdc-select-arrow {
    color: var(--color-primary) !important;;
  }

  .text-primary, .mat-icon.mat-primary, .mdc-tab-indicator--active {
    color: var(--color-primary) !important;
  }

  .mat-icon-no-color {
    color: var(--color-primary) !important;
  }

  .badge, .mdc-button--raised, .mdc-button--unelevated {
    background: linear-gradient(262.35deg, var(--color-gradient-start) 0%, var(--color-primary) 99.48%);
  }

  // remove main them dialog border. in flows applied with .mat-mdc-dialog-* classes
  // .mat-mdc-dialog-container {
  //   border: none !important;
  // }

  // basic button color = primary
  .mat-mdc-button:not(.mdc-icon-button):not(.mat-mdc-button-disabled) .mdc-button__label {
    color: var(--color-primary) !important;
  }

  --mat-stepper-header-selected-state-icon-background-color: var(--color-primary);
  --mat-datepicker-toggle-active-state-icon-color: var(--color-primary);
  --mdc-slider-inactive-track-color: var(--color-primary);
  --mat-full-pseudo-checkbox-selected-icon-color: var(--color-primary);
  --mdc-slider-hover-handle-color: var(--color-primary);
  --mdc-outlined-text-field-focus-outline-color: var(--color-primary);
  --mdc-slider-active-track-color: var(--color-primary);
  --mdc-circular-progress-active-indicator-color: var(--color-primary);
  --mdc-filled-text-field-focus-active-indicator-color: var(--color-primary);
  --mat-option-selected-state-label-text-color: var(--color-primary);
  --mdc-slider-focus-handle-color: var(--color-primary);
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: var(--color-primary);
  --mdc-filled-text-field-caret-color: var(--color-primary);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--color-primary);
  --mat-stepper-header-done-state-icon-background-color: var(--color-primary);
  --mat-badge-background-color: var(--color-primary);
  --mdc-outlined-text-field-caret-color: var(--color-primary);
  --mat-slider-ripple-color: var(--color-primary);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--color-primary);
  --mat-stepper-header-edit-state-icon-background-color: var(--color-primary);
  --mdc-slider-handle-color: var(--color-primary);
  --mdc-checkbox-selected-focus-icon-color: var(--color-primary);
  --mdc-checkbox-selected-focus-state-layer-color: var(--color-primary);
  --mdc-checkbox-selected-hover-icon-color: var(--color-primary);
  --mdc-checkbox-selected-hover-state-layer-color: var(--color-primary);
  --mdc-checkbox-selected-pressed-state-layer-color: var(--color-primary);
  --mdc-checkbox-selected-pressed-icon-color: var(--color-primary);
  --mdc-checkbox-selected-icon-color: var(--color-primary);
  --mat-radio-label-text-color: var(--color-primary);
}

[flowTheme='flow_intro'] {
  --color-primary:  #30A7F6;
  --color-primary-with-opacity: #30A7F666;
  --color-gradient-start: #00E0FF;

  button:not(.mdc-icon-button):not(.mat-mdc-button-disabled) .mat-icon-no-color{
    color: white !important;
  }

  .mdc-button--raised:not(.mat-mdc-button-disabled) .mdc-button__label, .mdc-button--unelevated .mdc-button__label, .badge {
    color: white;
  }

  button:not(.mdc-icon-button).mat-mdc-button-disabled .mat-icon-no-color {
    color: rgba(white, 0.45) !important;
  }

  .mdc-button--raised.mat-mdc-button-disabled .mdc-button__label, .mdc-button--unelevated .mat-mdc-button-disabled .mdc-button__label {
    color: rgba(white, 0.45) !important;
  }
}

[flowTheme='flow_sb'] {
  --color-primary: #FF55AA;
  --color-primary-with-opacity: #FF55AA66;
  --color-gradient-start: #BF37FF;

  button:not(.mdc-icon-button):not(.mat-mdc-button-disabled) .mat-icon-no-color{
    color: white !important;
  }

  .mdc-button--raised:not(.mat-mdc-button-disabled) .mdc-button__label, .mdc-button--unelevated .mdc-button__label, .badge {
    color: white;
  }

  button:not(.mdc-icon-button).mat-mdc-button-disabled .mat-icon-no-color {
    color: rgba(white, 0.45) !important;
  }

  .mdc-button--raised.mat-mdc-button-disabled .mdc-button__label, .mdc-button--unelevated .mat-mdc-button-disabled .mdc-button__label, .mdc-button.mat-mdc-button-disabled .mdc-button__label {
    color: rgba(white, 0.45) !important;
  }
}

[flowTheme='flow_mwm'] {
  --color-primary:  #594EE2;
  --color-primary-with-opacity: #594EE266;
  --color-gradient-start: #BF37FF;

  button:not(.mdc-icon-button):not(.mat-mdc-button-disabled) .mat-icon-no-color{
    color: white !important;
  }

  .mdc-button--raised:not(.mat-mdc-button-disabled) .mdc-button__label, .mdc-button--unelevated .mdc-button__label, .badge {
    color: white;
  }

  button:not(.mdc-icon-button).mat-mdc-button-disabled .mat-icon-no-color {
    color: rgba(white, 0.45) !important;
  }

  .mdc-button--raised.mat-mdc-button-disabled .mdc-button__label, .mdc-button--unelevated .mat-mdc-button-disabled .mdc-button__label {
    color: rgba(white, 0.45) !important;
  }
}

[flowTheme='flow_net_zero_y1'] {
  --color-primary:  #FF974B;
  --color-primary-with-opacity: #FF974B66;
  --color-gradient-start: #FFCE4C;

  button:not(.mdc-icon-button):not(.mat-mdc-button-disabled) .mat-icon-no-color{
    color: black !important;
  }

  .mdc-button--raised:not(.mat-mdc-button-disabled) .mdc-button__label, .mdc-button--unelevated:not(.mat-mdc-button-disabled) .mdc-button__label, .badge {
    color: black;
  }

  button:not(.mdc-icon-button).mat-mdc-button-disabled .mat-icon-no-color {
    color: rgba(black, 0.35) !important;
  }

  .mdc-button--raised.mat-mdc-button-disabled .mdc-button__label, .mdc-button--unelevated .mat-mdc-button-disabled .mdc-button__label {
    color: rgba(black, 0.35) !important;
  }
}

[flowTheme='flow_impacto'] {
  --color-primary:  #05F5BD;
  --color-primary-with-opacity: #013D2F;
  --color-gradient-start: #05F5BD;

  button:not(.mdc-icon-button):not(.mat-mdc-button-disabled) .mat-icon-no-color{
    color: black !important;
  }

  .mdc-button--raised:not(.mat-mdc-button-disabled) .mdc-button__label, .mdc-button--unelevated .mdc-button__label, .badge {
    color: black;
  }

  button:not(.mdc-icon-button).mat-mdc-button-disabled .mat-icon-no-color {
    color: rgba(black, 0.35) !important;
  }

  .mdc-button--raised.mat-mdc-button-disabled .mdc-button__label, .mdc-button--unelevated .mat-mdc-button-disabled .mdc-button__label {
    color: rgba(black, 0.35) !important;
  }
}

.mat-mdc-dialog-flow_intro {
  border: 2px solid #30A7F6;
  border-radius: 30px;
}

.mat-mdc-dialog-flow_sb {
  border: 2px solid #FF55AA !important;
  border-radius: 30px;
}

.mat-mdc-dialog-flow_mwm {
  border: 2px solid #594EE2;
  border-radius: 30px;
}

.mat-mdc-dialog-flow_net_zero_y1 {
  border: 2px solid #FF974B;
  border-radius: 30px;
}

.mat-mdc-dialog-flow_impacto {
  border: 2px solid #05F5BD;
  border-radius: 30px;
}