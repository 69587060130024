@import 'bootstrap/dist/css/bootstrap.css';

html,
body {
  height: 100%;
}
body {
  margin: 0;
}

.border {
  border-width: 2px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.active-link {
  border-bottom: 1px solid var(--color-primary);
  color: var(--color-primary) !important;
}

.mat-mdc-tooltip {
  background-color: #1e1e1e !important;
  color: rgba(white, 0.87) !important;
}

.mat-mdc-badge-content {
  font-family: 'Material Icons';
}

.w-33 {
  width: 33.3%;
}

.no-underline {
  text-decoration: none;
}

.h-full {
  height: 100%;
}

.atcb_bgoverlay.atcb_click:hover {
  cursor: pointer !important;
}

.disable-elements-and-childs {
  cursor: not-allowed !important;
  & > * {
    pointer-events: none !important;
    cursor: not-allowed !important;
  }
}

// QUIZ

@mixin answer($iconName, $iconColor) {
  .mdc-label {
    position: relative;

    &::before {
      font-family: 'Material Icons';
      content: $iconName;
      color: $iconColor;
      font-size: 1.5em;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -2.5em;
    }
  }
}

.wrong-answer {
  @include answer('close', red);
}

.right-answer {
  @include answer('done', green);
}


.text-underline {
  text-decoration: underline !important;
}
