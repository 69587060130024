@use '@angular/material' as mat;
@use '_spaceship-theme-colors' as spaceship-theme-colors;
@use '_campus-theme-colors' as campus-theme-colors;
@use '_flow-theme' as flow-theme;

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

html {
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Fix alignment of mat-icon-button and other buttons */
button[mat-icon-button] {
  vertical-align: bottom;

  mat-icon {
    vertical-align: top;
  }
}

body[theme='spaceship'] {
  color-scheme: dark;
  // bootstrap overrides to match material
  --bs-body-font-family: Inter;
  --bs-body-font-weight: 300;

  @include mat.theme((
    color: (
      primary: spaceship-theme-colors.$primary-palette,
      tertiary: spaceship-theme-colors.$tertiary-palette
    ),
    typography: Inter,
    bold-weight: 500,
    medium-weight: 400,
    regular-weight: 300,
    density: 0
  ));

  & {
    background-color: var(--mat-sys-surface);
    color: var(--mat-sys-on-surface);
  }

  .surface-bg {
    background-color: rgba(22, 17, 49, 0.75);
    border: solid;
    border-color: #6e65ffa5;
    border-radius: 15px;
    padding: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  #back-to-top {
    background-color: var(--mat-sys-surface-container-low) !important;
  }
}

@at-root body[theme='spaceship'] {
  --color-primary: #6e65ff;
  --mat-sys-primary: #6e65ff;
  --mat-sys-surface-container-low: #393842;

  @include mat.card-overrides((
    elevated-container-color: #161131,
    subtitle-text-weight: 300
  // border: 2px solid #6e65ff;
  ));

  @include mat.expansion-overrides((
    header-text-weight: 300,
    container-text-weight: 300
  ));

  @include mat.dialog-overrides((
    headline-padding: 12px
  ));

  .mat-mdc-dialog-panel:not([class*='mat-mdc-dialog-flow'])  {
    border: 2px solid var(--color-primary);
    border-radius: 30px;
  }

  .mat-mdc-dialog-surface {
    padding: 12px;
  }

  .border-primary {
    border-color: var(--color-primary) !important;
  }

  .text-primary {
    color: var(--color-primary) !important;
  }

  .bg-primary {
    background-color: var(--color-primary) !important;
  }

  // .surface-bg[theme='spaceship'] {
  //   background-color: rgba(22, 17, 49, 0.75);
  //   border: solid;
  //   border-color: #6e65ff;
  //   border-radius: 15px;
  //   padding: 40px;
  //   margin-top: 20px;
  //   margin-bottom: 20px;
  // }

  h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1, p {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 300 !important;;
    line-height: 1.2;
  }

  .layout-content {
    background-image: url('../../assets/img/stars-bg.jpg');
    background-size: 100%;
  }
}

body[theme='campus'] {
  color-scheme: dark;
  // bootstrap overrides to match material
  --bs-body-font-family: Poppins;
  --bs-body-font-weight: 300;

  @include mat.theme((
    color: (
      primary: campus-theme-colors.$primary-palette,
      tertiary: campus-theme-colors.$tertiary-palette
    ),
    typography: Poppins,
    bold-weight: 500,
    medium-weight: 400,
    regular-weight: 300,
    density: 0
  ));

  & {
    color: var(--mat-sys-on-surface);
  }
}

@at-root body[theme='campus'] {
  --mat-sys-surface: #5864A9;
  --color-primary: var(--mat-sys-primary);
  --mat-sys-surface-container-low:#818bc2; // rgba(136, 147, 211, 0.75);
  --bs-body-bg: var(--mat-sys-surface);

  @include mat.card-overrides((
    subtitle-text-weight: 300
  // border: 2px solid #6e65ff;
  ));

  @include mat.expansion-overrides((
    header-text-weight: 300,
    container-text-weight: 300
  ));

  .border-primary {
    border-color: var(--color-primary) !important;
  }

  .text-primary {
    color: var(--color-primary) !important;
  }

  .bg-dark {
    background-color: var(--mat-sys-surface-container-low) !important;
  }

  footer.bg-dark {
    background-color: black !important;
  }

  mat-radio-button .mdc-label {
    color: black;
  }

  .bg-primary {
    background-color: var(--color-primary) !important;
  }

  h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1, p {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 300;
    line-height: 1.2;
  }

  mat-expansion-panel {
    margin-right: 5px;
    margin-left: 5px;
  }

  .mat-mdc-tab .mdc-tab__text-label {
    color: var(--mat-sys-on-surface);
  }

  .surface-bg[theme='spaceship'] {
    background-color: var(--mat-sys-surface-container-low);
    border: solid;
    border-color: var(--mat-sys-primary);
    border-radius: 15px;
    padding: 40px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  app-news-card mat-card {
    border-color: transparent !important;
  }

  mat-card {
    box-shadow: none !important;
    outline: none !important;
  }
}